<template lang="pug">
  Section.cy-points-section(
    required
    :class="{ 'invalid-section': object.$error }"
    :title="$t('plan_settings.basic_settings.sections.points.section_name')"
  )
    AppNumberInput.m-r-4(
      :invalid="object.$error"
      :max="100"
      v-model="object.$model"
    )
    span {{ "%" }}
</template>

<script>
  export default {
    props: {
      object: Object
    },

    components: {
      Section: () => import("@/pages/PriceManagement/PlanSettings/BasicSettings/Section"),
      AppNumberInput: () => import("@/components/elements/AppNumberInput")
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"

  .cy-points-section
    +styled-inputs
</style>
